export default {
  env: 'production', // TODO: 'local' or 'staging' or 'production'
  // System Config
  apiUrl: 'http://localhost:5001',
  dashboardUrl: 'https://dash.craftsake.com',
  appUrl: 'https://dist.craftsake.com',
  appManualBreweryUrl: 'https://sake-blockchain-brewery.zendesk.com/hc/ja',
  appManualRestaurantUrl: 'https://sake-blockchain-restaurant.zendesk.com/hc/en-gb',
  appManualDistributorUrl: 'https://sake-blockchain.zendesk.com/hc/en-gb',
  cloudFunctionUrl: 'https://asia-northeast1-SAKEBlockchain-Dist-Campaign.cloudfunctions.net',
  rootDir: '/',
  qrDomain: 'craftsake.com',
  enableSerialEdit: true,
  includeDistributor: true,
  cryptoKey: 5291327429,
  // Firebase Config
  region: 'asia-northeast1',
  firebaseConfig: {
    apiKey: "AIzaSyB-oF67oGtF_LjB5SteVkj3v-bg5yEQRZ0",
    authDomain: "sakeblockchain-dist-campaign.firebaseapp.com",
    databaseURL: "https://sakeblockchain-dist-campaign.firebaseio.com",
    projectId: "sakeblockchain-dist-campaign",
    storageBucket: "sakeblockchain-dist-campaign.appspot.com",
    messagingSenderId: "665953357639",
    appId: "1:665953357639:web:e9c7fe88ddab5288878662",
    measurementId: "G-S6WYBZWGS6"
  },
  // Google Config
  googleMapApiKey: 'AIzaSyB-oF67oGtF_LjB5SteVkj3v-bg5yEQRZ0',
  // My App Settings
  countryMap: {
    ja: {
      JP: '日本',
      US: '米国',
    },
    en: {
      JP: 'Japan',
      US: 'USA',
    },
    zhcn: {
      JP: '日本',
      US: '美国',
    },
    zhtw: {
      JP: '日本',
      US: '美國',
    },
    ko: {
      JP: '일본',
      US: '미국',
    },
    th: {
      JP: 'ญี่ปุ่น',
      US: 'สหรัฐอเมริกา',
    },
    vi: {
      JP: 'Nhật Bản',
      US: 'Hoa Kỳ',
    },
  },
  categoryMapUserRole: {
    ja: {
      0: '管理者',
      1: '酒蔵',
      2: 'ディストリビュータ',
      3: 'レストラン'
    },
    en: {
      0: 'Admin',
      1: 'Brewery',
      2: 'Distributor',
      3: 'Restaurant'
    },
    zhcn: {
      0: '管理员权限',
      1: '酿酒厂',
      2: '分销商',
      3: '餐厅'
    },
    zhtw: {
      0: '管理員權限',
      1: '釀酒廠',
      2: '分銷商',
      3: '餐廳'
    },
    ko: {
      0: '관리자 권한',
      1: '양조장',
      2: '유통',
      3: '레스토랑'
    },
    th: {
      0: 'ผู้ดูแลระบบ',
      1: 'โรงเบียร์',
      2: 'ตัวแทนจำหน่าย',
      3: 'ร้านอาหาร'
    },
    vi: {
      0: 'Quản trị viên',
      1: 'Nhà máy bia',
      2: 'Nhà phân phối',
      3: 'Nhà hàng'
    },
  },
  categoryMapLocation: {
    ja: {
      0: '指定なし',
      1: '酒蔵',
      2: 'ディストリビュータ',
      3: 'レストラン'
    },
    en: {
      0: 'Unspecified',
      1: 'Brewery',
      2: 'Distributor',
      3: 'Restaurant'
    },
    zhcn: {
      0: '不明',
      1: '酿酒厂',
      2: '分销商',
      3: '餐厅'
    },
    zhtw: {
      0: '不明',
      1: '釀酒廠',
      2: '分銷商',
      3: '餐廳'
    },
    ko: {
      0: '지정 없음',
      1: '양조장',
      2: '유통',
      3: '레스토랑'
    },
    th: {
      0: 'ไม่ระบุ',
      1: 'โรงเบียร์',
      2: 'ตัวแทนจำหน่าย',
      3: 'ร้านอาหาร'
    },
    vi: {
      0: 'Không xác định',
      1: 'Nhà máy bia',
      2: 'Nhà phân phối',
      3: 'Nhà hàng'
    },
  },
  subCategoryMap: {
    ja: {
      0: "レストラン",
      1: "ホテル",
      2: "会員制クラブ",
      3: "ダイニングバー",
      5: "個人顧客",
      6: "イベント",
      7: "社内利用",
    },
    en: {
      0: "Restaurant",
      1: "Hotel",
      2: "Private Members Club",
      3: "Lounge Bar/Bar",
      5: "Private Customer",
      6: "Event",
      7: "Internal Use",
    },
    zhcn: {
      0: "餐廳",
      1: "酒店",
      2: "會員俱樂部",
      3: "餐廳酒吧",
      5: "私人客户",
      6: "活動",
      7: "内部使用",
    },
    zhtw: {
      0: "餐厅",
      1: "酒店",
      2: "会员俱乐部",
      3: "餐厅酒吧",
      5: "私人客戶",
      6: "活動",
      7: "内部使用",
    },
    ko: {
      0: "레스토랑",
      1: "호텔",
      2: "회원제 클럽",
      3: "다이닝 바",
      5: "개인 고객",
      6: "이벤트",
      7: "내부 사용",
    },
    th: {
      0: "ร้านอาหาร",
      1: "โรงแรม",
      2: "สโมสรส่วนตัว",
      3: "บาร์",
      5: "ลูกค้าส่วนตัว",
      6: "เหตุการณ์",
      7: "การใช้งานภายใน",
    },
    vi: {
      0: "Nhà hàng",
      1: "Khách sạn",
      2: "Câu lạc bộ thành viên",
      3: "Quán bar",
      5: "Khách hàng cá nhân",
      6: "Sự kiện",
      7: "Sử dụng nội bộ",
    },
  },
  operationMap: {
    ja: {
      0: '指定なし',
      1: '出荷',
      2: '入荷',
      3: '販売'
    },
    en: {
      0: 'Unspecified',
      1: 'Shipping',
      2: 'Receiving',
      3: 'Selling'
    },
    zhcn: {
      0: '不明',
      1: '运输',
      2: '货到',
      3: '销售'
    },
    zhtw: {
      0: '不明',
      1: '運輸',
      2: '貨到',
      3: '銷售'
    },
    ko: {
      0: '지정 없음',
      1: '출하',
      2: '입하',
      3: '판매'
    },
    th: {
      0: 'ไม่ระบุ',
      1: 'การจัดส่ง',
      2: 'การรับ',
      3: 'การขาย'
    },
    vi: {
      0: 'Không xác định',
      1: 'Vận chuyển',
      2: 'Nhận hàng',
      3: 'Bán hàng'
    },
  },
  dateFormat: {
    ja: 'yyyy/MM/dd',
    en: 'dd/MM/yyyy',
    zhcn: 'dd/MM/yyyy',
    zhtw: 'dd/MM/yyyy',
    ko: 'dd/MM/yyyy',
    th: 'dd/MM/yyyy',
    vi: 'dd/MM/yyyy',
  },
};